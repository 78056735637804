<template>
  <div :class="cardClass" class="card">
    <div class="card-header p-0 d-flex" :class="'bg-'+ program.slug">
      <cs-button :iconLeft="program.slug"
                 :title="program.name"
                 :truncateText="true"
                 :type="program.slug"
                 @handle-click.prevent="goProgram()"
                 class="flex-fill"

      >
        <span v-html="program.title"/>
      </cs-button>
      <cs-disclose v-model="expanded"
                   v-if="!this.isHome"
                   un-disclosed-class="link"
                   disclosed-class="link"
                   :class="headerButtonClass"

      />
    </div>
    <template v-if="expanded">
      <div class="card-body" v-if="isHome">
        <div class="text-center" v-if="program.badge_path">
          <img
              :src="program.badge_path"
              alt="program logo"
              height="150"
              width="150"
          />
        </div>
        <div class="text-center">
          <cs-button
              @handle-click.prevent="briefExpanded = !briefExpanded"
              iconLeft="info"
              :type="program.slug"
              v-if="program.brief"
          >
            {{ !briefExpanded ? 'Show Description' : 'Hide Description' }}
          </cs-button>
        </div>
        <hr v-if="program.brief && briefExpanded"/>
        <transition>
          <span v-html="program.brief" v-if="program.brief && briefExpanded"/>
        </transition>
      </div>
      <div v-if="!activeRegistrations"
           class="card-body bg-warning-light"
           v-wait:hidden="'registrations.active.load' + program.slug">
        <p>
          There are no active registrations for {{ program.title }} or open
          registration periods.
        </p>
      </div>
      <div class="list-group list-group-flush">
        <cs-loading theme="info"
                    :sm="true"
                    class="list-group-item"
                    v-wait:visible="'students.eligible.load' + program.slug">
          Loading eligible students
        </cs-loading>
        <cs-loading theme="info"
                    :sm="true"
                    class="list-group-item"
                    v-wait:visible="'registrations.active.load' + program.slug">
          Loading active registrations
        </cs-loading>
        <div :key="'registration-' + program.slug + '-' + year"
             class="list-group-item text-center"
             v-for="(registration, year) in registrations"
             v-wait:hidden="'registrations.load'">
          <cs-button :class="'btn-' + program.slug"
                     :outline="true"
                     @handle-click.prevent="goRegistration(registration.id, $event)"
                     class="btn">
            Go to {{ schoolYear(year) }} Registration: {{ registration.id }}
          </cs-button>
        </div>
        <div :key="'registration-new-' + program.slug + '-' + year"
             class="list-group-item text-center"
             v-for="year in years"
             v-wait:hidden="'registrations.load'">
          <transition mode="out-in" name="fade">
            <cs-button :class="'btn-' + program.slug"
                       :outline="true"
                       @handle-click.prevent="showStartNew[year] = true"
                       class="btn"
                       iconLeft="registration"
                       v-if="!showStartNew[year]">
              New {{ schoolYear(year) }} Registration
            </cs-button>
            <div v-else-if="eligibleStudents.length > 0"
                 v-wait:hidden="'students.eligible.load' + program.slug"
                 class="text-left">
              <cs-button :outline="true"
                         :type="'warning'"
                         @handle-click.prevent="showStartNew[year] = false"
                         class="float-right m-1 rounded-circle"
                         iconLeft="cancel"/>
              Select student to start <strong>{{ schoolYear(year) }}</strong> registration with. You can add other
              students after. If you do not see a student you expect you need
              <a href="/profile#student">check your students</a> grade and school.
              <template v-for="student in eligibleStudents">
                <cs-button :key="'registration-' + program.slug + '-' + year +'-' +student.id"
                           :outline="true"
                           @handle-click.prevent="goNewRegistration(year, student)"
                           customClass="btn-block"
                           iconLeft="add-student"
                           type="primary">
                  Start with {{ student.first }}
                  <small>({{ displayAbbreviation(student.school) }}-{{ student.grade }})</small>
                </cs-button>
              </template>
              <cs-button :key="'new_' + year"
                         @handle-click.prevent="goNewStudent(year)"
                         customClass="btn-block"
                         iconLeft="add-student"
                         type="primary">
                New student to register for {{ schoolYear(year) }}
              </cs-button>
              <template v-if="ineligibleStudents.length > 0">
                <div class="alert alert-warning p-1 text-small">
                  <strong>Ineligible:</strong> {{
                    toList(ineligibleList)
                  }}<br/>
                  <cs-button type="info"
                             @handle-click="goStudents()"
                             iconLeft="edit"
                             size="sm">
                    Edit
                  </cs-button>
                </div>
              </template>
            </div>
            <div v-else v-wait:hidden="'students.eligible.load' + program.slug" class="text-left">
              <cs-button :class="'btn-warning'"
                         :key="'registration-' + program.slug + '-' + year"
                         :outline="true"
                         @handle-click.prevent="showStartNew[year] = false"
                         class="float-right m-1 rounded-circle"
                         iconLeft="cancel"/>
              You do not have students eligible for <span v-html="program.name"/>. You
              can start with a new student, or
              <a href="/profile#students">check your students</a> grade and school.
              <cs-button :key="'new_' + year"
                         @handle-click.prevent="goNewStudent(year)"
                         class="btn-block"
                         iconLeft="add-student"
                         type="primary">
                New student to register for {{ schoolYear(year) }}
              </cs-button>
              <template v-if="ineligibleStudents.length > 0">
                <br/>
                <div class="alert alert-warning p-1 text-small">
                  <strong>Ineligible:</strong> {{ toList(ineligibleList) }}<br/>
                  <cs-button type="info"
                             @handle-click.prevent="goStudents()"
                             iconLeft="edit"
                             size="small">
                    Edit
                  </cs-button>
                </div>
              </template>
            </div>
          </transition>
        </div>
      </div>
    </template>
    <div class="card-footer text-center" v-if="expanded">
      <cs-button :iconLeft="program.slug"
                 :key="'program-go-' + program.slug"
                 :outline="true"
                 :type="program.slug"
                 @handle-click.prevent="goProgram()"
                 class="btn-block">
        Go to <span v-html="program.title"/>
      </cs-button>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { API, urlBuilder } from '@/utilities/http-common'
import { toList } from '@/utilities/stringFormatters'
import { schoolYear } from '@/utilities/dateFormatters'
import {
  INITIAL_STATE_HAS_UNPAID,
  INITIAL_STATE_FORCE_PAY_UNPAID,
  INITIAL_STATE_BLOCK_ENROLMENTS_DUE_UNPAID
} from '@/constants/application'
import CsDisclose from '@/components/controls/CsDisclose'
import { displayAbbreviation, InitialState } from '@/utilities/cs-utilities'

export default {
  name: 'ProgramMini',
  components: { CsDisclose },
  props: {
    expand: {
      type: Boolean,
      default: true
    },
    program: {
      type: Object,
      required: true
    },
    member: { type: Object, required: true },
    isHome: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      expanded: false,
      eligibleStudents: [],
      ineligibleStudents: [],
      showStartNew: {},
      briefExpanded: false,
      hasUnpaid: false,
      forcePayUnpaid: false,
      blockEnrollments: false,
      registrations: {},
      darkButtonTextPrograms: ['summer', 'outdoor']
    }
  },
  created () {
    if (typeof window.__INITIAL_STATE__ !== 'undefined') {
      const initialState = window.__INITIAL_STATE__
      this.hasUnpaid = initialState[INITIAL_STATE_HAS_UNPAID]
      this.forcePayUnpaid = initialState[INITIAL_STATE_FORCE_PAY_UNPAID]
      this.blockEnrollments =
          initialState[INITIAL_STATE_BLOCK_ENROLMENTS_DUE_UNPAID]
    }
    if (this.years.length > 0) {
      var vm = this
      this.years.forEach(function (year) {
        vm.$set(vm.showStartNew, year, false)
      })
    }
    if (this.expand) {
      this.expanded = true
      this.loadRegistrations()
      this.loadEligibleStudents()
    }
  },
  watch: {
    expanded (isExpanded) {
      if (isExpanded) {
        this.loadRegistrations()
        this.loadEligibleStudents()
      }
    }
  },
  computed: {
    currentProgramSlug () {
      const initialState = InitialState()
      if (!isEmpty(initialState.program)) {
        return initialState.program.slug
      }

      return ''
    },
    ineligibleList () {
      const list = this.ineligibleStudents.map(function (student) {
        return (
          student.first + ' (' + student.grade + '/' + displayAbbreviation(student.school) + ')'
        )
      })
      return list
    },
    activeRegistrations () {
      return this.years.length !== 0 || !isEmpty(this.registrations)
    },
    cardClass () {
      const result = ['border-' + this.program.slug, this.program.slug]
      if (this.isHome) {
        result.push('m-2')
      }
      return result
    },
    headerButtonClass () {
      const classes = []
      if (!this.darkButtonTextPrograms.includes(this.program.slug)) {
        classes.push('text-light')
      }
      return classes
    },
    years () {
      const years = []
      const now = new Moment()
      const program = this.program
      const thisYearStart = Moment(program.registration_start)
      const thisYearEnd = Moment(program.registration_end)
      const nextYearStart = Moment(program.next_year_registration_start)
      const nextYearEnd = Moment(program.next_year_registration_end)
      const year = program.current_year
      if (
        now.isBetween(thisYearStart, thisYearEnd, 'hour') &&
          typeof this.registrations[year] === 'undefined'
      ) {
        years.push(year)
      }
      if (
        now.isBetween(nextYearStart, nextYearEnd, 'hour') &&
          typeof this.registrations[year + 1] === 'undefined'
      ) {
        years.push(year + 1)
      }
      return years
    }
  },
  methods: {
    displayAbbreviation,
    schoolYear,
    toList,
    loadEligibleStudents () {
      this.$wait.start('students.eligible.load' + this.program.slug)
      API.get('programs/' + this.program.id + '/students/eligible.json', {
        params: {
          member_id: this.member.id,
          fallback_prior_years: true
        }
      })
        .then(result => {
          if (result.status === 200) {
            if (
              typeof result.data.eligible !== 'undefined' &&
                  result.data.eligible !== null
            ) {
              this.eligibleStudents = result.data.eligible
            }
            if (
              typeof result.data.ineligible !== 'undefined' &&
                  result.data.elineligibleigible !== null
            ) {
              this.ineligibleStudents = result.data.ineligible
            }
            this.$wait.end('students.eligible.load' + this.program.slug)
          }
        })
        .catch(e => {
          // eslint-disable-next-line
            console.log(e)
          this.$wait.end('students.eligible.load' + this.program.slug)
        })
    },
    loadRegistrations () {
      this.$wait.start('registrations.active.load' + this.program.slug)
      const params = {
        program_id: this.program.id,
        years: this.years
      }
      API.get('/registrations/active', { params: params })
        .then(result => {
          this.$wait.end('registrations.active.load' + this.program.slug)
          if (!isEmpty(result.data) && !isEmpty(result.data.registrations)) {
            const registrations = result.data.registrations
            registrations.forEach(registration => {
              this.$set(this.registrations, registration.year, registration)
            })
          }

          console.log('result', result)
        })
    },
    goStudents () {
      window.location = urlBuilder('/students')
    },
    goNewRegistration (year, student) {
      const params = {
        year: year,
        program: this.program.slug,
        next: 'new_registration'
      }
      if (typeof student !== 'undefined') {
        params.student_id = student.id
      }
      window.location = urlBuilder('/registrations/new', params)
    },
    goNewStudent (year) {
      const params = {
        year: year,
        program: this.program.slug,
        next: 'new_registration',
        return_to: encodeURI(window.location)
      }
      window.location = urlBuilder('/students/add', params)
    },
    goProgram () {
      window.location = '/' + this.program.slug
    },
    goRegistration (registrationId, event) {
      if (!isEmpty(event)) {
        event.target.disabled = true
      }
      window.location =
          '/registrations/view?registration_id=' + registrationId
    }
  }

}
</script>

<style scoped>
</style>
