<template>
  <div class="w-100">
    <div class="row" v-if="isHome" v-responsive="middleSize">

      <div class="col text-center">
        <h1>
          Community Schools Programs
        </h1>
      </div>
    </div>
    <div
      v-wait:visible="['programs.load', 'registrations.load']"
      class="alert alert-info col-8 mx-auto"
    >
      <div class="row">
        <div class="col" v-wait:visible="'programs.load'">
          <cs-loading theme="success">
            Loading programs
          </cs-loading>
        </div>
        <div class="col" v-wait:visible="'registrations.load'">
          <cs-loading theme="success">
            Checking for active registrations
          </cs-loading>
        </div>
      </div>
    </div>
    <div v-wait:hidden="'programs.load'" class="" aria-label="Programs">
      <div v-responsive="smallSize">
        <cs-button
          v-if="!isHome"
          @handle-click="openMenu()"
          type="primary"
          :iconRight="drawerOpen ? 'down' : 'right'"
          iconLeft="bars"
        >
          {{ drawerOpen ? "Hide Programs" : "Show Programs" }}
        </cs-button>
        <app-drawer :direction="'left'" :exist="true" ref="LeftDrawer">
          <cs-button
            @handle-click="openMenu()"
            type="primary"
            :iconRight="drawerOpen ? 'down' : 'right'"
            iconLeft="bars"
          >
            {{ drawerOpen ? "Hide Programs" : "Show Programs" }}
          </cs-button>
          <div class="row row-cols-1">
            <div v-for="program in programs" :key="program.id">
              <program-mini
                :program="program"
                :member="member"
                :expand="isHome"
                :isHome="isHome"
              />
            </div>
          </div>
        </app-drawer>
      </div>
      <div v-responsive="middleSize">
        <template>
          <div
            class="row row-cols-1 row-cols-md-2  row-cols-lg-3 row-cols-xl-4"
          >
            <template v-for="program in programs" >
              <span v-if="program.slug !== currentProgramSlug" :key="program.id">
              <program-mini
                  v-if="program.slug !== currentProgramSlug"
                :program="program"
                :member="member"
                :expand="expand"
                :isHome="isHome"
              />
              </span>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-wait:hidden="'programs.load'" class="" aria-label="Programs"></div>
  </div>
</template>

<script>
import Drawer from '@/components/controls/Drawer'
import Member from '@/models/Member'
import Program from '@/models/Program'
import ProgramMini from './ProgramMini'
import isEmpty from 'lodash/isEmpty'
import { API } from '@/utilities/http-common'

import { InitialState } from '@/utilities/cs-utilities'

export default {
  name: 'MemberHeader',
  components: {
    programMini: ProgramMini,
    appDrawer: Drawer
  },
  data () {
    return {
      member_id: null,
      showPrograms: false,
      expand: false,
      middleSize: ['hidden-all', 'lg', 'xl'],
      smallSize: ['hidden-all', 'xs', 'sm', 'md'],
      drawerOpen: false,
      isHome: false,
      showCourseSearch: true,
      showLegacyCourseSearch: true,
      searchHasFocus: false
    }
  },
  created () {
    if (process.env.VUE_APP_NEW_COURSE_SEARCH === 'undefined') {
      this.showLegacyCourseSearch = true
    } else {
      this.showLegacyCourseSearch =
          process.env.VUE_APP_NEW_COURSE_SEARCH === 'false'
    }
    const initialState = InitialState()
    if (typeof initialState.expand !== 'undefined') {
      this.expand = true
    }
    if (typeof initialState.is_home !== 'undefined') {
      this.isHome = true
      this.expand = true
    }
    if (!isEmpty(initialState.auth_member)) {
      var member = initialState.auth_member
      this.member_id = member.id
      Member.insert({ data: member })
    }
    if (typeof initialState.programs !== 'undefined') {
      const programs = initialState.programs
      programs.forEach(function (program) {
        Program.insert({ data: program })
      })
    }
    if (isEmpty(this.programs) && !this.$wait.waiting('programs.load')) {
      this.$wait.start('programs.load')
      API.get('programs')
        .then(result => {
          if (result.status === 200) {
            const programs = result.data.programs
            programs.forEach(function (program) {
              Program.insert({ data: program })
            })
            this.$wait.end('programs.load')
          }
        })
        .catch(e => {
          // eslint-disable-next-line
            console.log(e)
          this.$wait.end('programs.load')
        })
    }
  },
  computed: {
    currentProgramSlug () {
      const initialState = InitialState()
      if (!isEmpty(initialState.program)) {
        return initialState.program.slug
      }
      return ''
    },
    member () {
      return Member.find(this.member_id)
    },
    programs () {
      const programs = Program.query()
      return programs
        .orderBy('sort')
        .where('active', true)
        .get()
    }
  },
  methods: {
    searchGotFocus (gotFocus) {
      this.searchHasFocus = gotFocus
    },
    openMenu () {
      if (this.$refs.LeftDrawer.active) {
        this.drawerOpen = false
        this.$refs.LeftDrawer.close()
      } else {
        this.drawerOpen = true
        this.$refs.LeftDrawer.open()
      }
    }
  }

}
</script>

<style scoped></style>
